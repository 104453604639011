import {Grid, Text,  Divider } from "@nextui-org/react";

export const Footer = () => {
  return (
    <footer>
        <Divider />
        <Grid.Container justify='space-evenly' gap={4}>
          <Grid>
            <Text size={15} >
              totalcokeup © 2022
            </Text>
          </Grid>
        </Grid.Container>
    </footer>
  )
}

export default Footer;