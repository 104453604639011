import { Link as RouterLink } from "react-router-dom";
import { Grid, Link, Text, Divider, Collapse } from "@nextui-org/react";
import { BiMenu } from "react-icons/bi";
import { SetTheme } from "../../hooks/useTheme";
import { ModalContent, useModal } from "../../hooks";
import { navLinks } from "../../consts/NavLinks";
import { purpleCssGradient } from '../../consts/CssColours';

export type HeaderProps = {
  onThemeChange: SetTheme;
};

export const Header = (props: HeaderProps) => {
  const modalContent: ModalContent = {
    menu: () => (
      <Collapse.Group>
        {navLinks.map(({ text, path }) => (
          <div key={`menu-${text}`}>
            <Divider />
            <RouterLink to={path} onClick={closeModal}>
              <Collapse title={text} arrowIcon={<Text size={25} />} />
            </RouterLink>
          </div>
        ))}
      </Collapse.Group>
    ),
  };

  const { setSelectedModal, Modal, closeModal } = useModal(modalContent);

  return (
    <nav>
      <Modal />
      <Grid.Container justify="space-between" gap={1} alignItems="baseline">
        <Grid xs={3}>
          <RouterLink to="/">
            <Text weight="bold" size={40} css={purpleCssGradient}>
              totalcokeup
            </Text>
          </RouterLink>
        </Grid>

        <Grid xs={9}>
          <Grid.Container justify="flex-end" gap={1} alignItems="center">
            {navLinks.map(({ path, text }) => (
              <Grid
                xs={0}
                sm={1.2}
                md={1}
                lg={0.9}
                xl={0.6}
                key={`nav-${text}`}
              >
                <RouterLink to={path}>
                  <Text>{text}</Text>
                </RouterLink>
              </Grid>
            ))}

            <Grid sm={0}>
              <Link>
                <Text size={25} onClick={() => setSelectedModal("menu")}>
                  <BiMenu />
                </Text>
              </Link>
            </Grid>
          </Grid.Container>
        </Grid>
      </Grid.Container>
      <Divider />
    </nav>
  );
};

export default Header;
