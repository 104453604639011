import { Grid, Input, Text } from "@nextui-org/react";
import { purpleCssGradient } from "../../consts/CssColours";
import MainLayout from "../../Layouts/MainLayout";

export const LoginPage = () => {
  return (
    <MainLayout>
      <Grid xs={12}>
        <Text h1 css={purpleCssGradient}>
          Reviewer Login
        </Text>
        <Input type="text">Username</Input>
        <Input type="password">Password</Input>
      </Grid>
    </MainLayout>
  );
};
export default LoginPage;
