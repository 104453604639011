import { Card, Grid, Spacer, Text } from "@nextui-org/react";
import { useNavigate } from "react-router-dom";
import { purpleCssGradient } from "../../consts/CssColours";
import MainLayout from "../../Layouts/MainLayout";

const pubRatings = [
  {
    title: "Pub A",
    path: "./next-ui-react-hook-form",
    rating: "chewable",
  },
];

export const PubListingPage = () => {
  const navigate = useNavigate();
  return (
    <MainLayout>
      <Grid xs={12}>
        <Text h4>The </Text>
        <Text h1 css={purpleCssGradient}>
          {" "}
          Pubs
        </Text>
      </Grid>
      {pubRatings.map(({ title, path, rating }, index) => (
        <Grid key={`${path}-${index}`} xs={12} sm={6} md={6} lg={4}>
          <Card isHoverable isPressable onClick={() => navigate(path)}>
            <Card.Body css={{ py: "$10" }}>
              <Text h4>{title}</Text>
              <Spacer />
              <Text>{rating}</Text>
            </Card.Body>
          </Card>
        </Grid>
      ))}
    </MainLayout>
  );
};
export default PubListingPage;
