import { Container, Dropdown, Grid, Input } from "@nextui-org/react";
import MainLayout from "../../Layouts/MainLayout";

export const PubSearchPage = () => {
  return (
    <MainLayout>
      <Container>
        <Grid xs={12}>
          <Input labelPlaceholder="Search" name="search" />
          <Dropdown>
            <Dropdown.Button color="secondary">Rating</Dropdown.Button>
            <Dropdown.Menu color="secondary">
              <Dropdown.Item>Chewable</Dropdown.Item>
              <Dropdown.Item>Ok</Dropdown.Item>
              <Dropdown.Item>Bottled</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Grid>
      </Container>
    </MainLayout>
  );
};
export default PubSearchPage;
