import { Grid, Text } from "@nextui-org/react";
import { purpleCssGradient } from "../../consts/CssColours";
import MainLayout from "../../Layouts/MainLayout";

export const HomePage = () => {
  return (
    <MainLayout>
      <Grid xs={12}>
        <Text size={60}>
          <Text size={60} span css={purpleCssGradient}>
            chewable
          </Text>{" "}
          Cola
        </Text>
      </Grid>
      <Grid xs={12}>
        <Text size={60}>
          Rated{" "}
          <Text size={60} span css={purpleCssGradient}>
            for you
          </Text>
        </Text>
      </Grid>
    </MainLayout>
  );
};

export default HomePage;
