import { Container, Grid, Text } from "@nextui-org/react";
import { purpleCssGradient } from "../../consts/CssColours";
import MainLayout from "../../Layouts/MainLayout";
import { ReviewData } from "../../types";

const pubReview: ReviewData = {
  pubName: "pub name",
  rating: 1,
  address: { lineOne: "line one", postcode: "AB01CBD" },
  review: "is better than great, chewable",
};

export const PubDisplayPage = () => {
  return (
    <MainLayout>
      <Container>
        <Grid xs={12}>
          <Text h1 css={purpleCssGradient}>
            {pubReview.pubName}
          </Text>
        </Grid>
        <Grid>
          <Text h3>{pubReview.rating}</Text>
        </Grid>
        <Grid>
          <Text h3>{pubReview.address.lineOne}</Text>
          <Text h3>{pubReview.address?.lineTwo}</Text>
          <Text h3>{pubReview.address.postcode}</Text>
        </Grid>
        <Grid>
          <Text h3>{pubReview.review}</Text>
        </Grid>
      </Container>
    </MainLayout>
  );
};
export default PubDisplayPage;
