import { Container, Grid, Input } from "@nextui-org/react";
import MainLayout from "../../Layouts/MainLayout";

export const PubReviewPage = () => {
  return (
    <MainLayout>
      <Container>
        <Grid xs={12}>
          <Input></Input>
        </Grid>
      </Container>
    </MainLayout>
  );
};
export default PubReviewPage;
