import {
  PubDisplayPage,
  PubReviewPage,
  LoginPage,
  PubSearchPage,
  PubListingPage,
} from "../pages";

export type NavLink = {
  path: string;
  text: string;
  icon?: React.FC;
  element: JSX.Element;
};

export const navLinks: NavLink[] = [
  { path: "/pubs", text: "Pubs", element: <PubListingPage /> },
  { path: "/pubdisplay", text: "Display", element: <PubDisplayPage /> },
  { path: "/pubreview", text: "Review", element: <PubReviewPage /> },
  { path: "/search", text: "Search", element: <PubSearchPage /> },
  { path: "/login", text: "Login", element: <LoginPage /> },
];
